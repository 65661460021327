import React from "react";

// Root Include
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
// Import all components
const home = React.lazy(() => import('./pages/home/index'));

const routes = [
  //routes without Layout
  { path: "/index", component: home },
  
  //Index root

  { path: "/", component: home, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
